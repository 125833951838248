import { Injectable } from '@angular/core';
import { BehaviorSubject, from, map, Observable, of, switchMap } from 'rxjs';
import {
  Auth,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getIdTokenResult,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
  User
} from '@angular/fire/auth';
// @ts-ignore
import { authState } from 'rxfire/auth';
import { UserService, Utilisateur } from './user-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  user$: Observable<User | null> = this.userSubject.asObservable();

  constructor(private auth: Auth, private userService: UserService) {
    onAuthStateChanged(this.auth, (user) => {
      this.userSubject.next(user);  // Met à jour le BehaviorSubject
    });
  }

  get currentUser() {
    return this.auth.currentUser; // Optionnel : Accès direct à currentUser
  }

  signUp(utilisateur: Utilisateur, password: string) {
    return createUserWithEmailAndPassword(this.auth, utilisateur.email!, password)
      .then((userCredential) => {
        updateProfile(userCredential.user, { displayName: utilisateur.displayName });

        this.userService.create({
          userId: userCredential.user.uid,
          email:utilisateur.email,
          displayName:utilisateur.displayName,
          phoneNumber:utilisateur.phoneNumber,
        })
        this.refreshUserToken();
        return userCredential;
      });
  }

  signIn(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        this.refreshUserToken();
        return userCredential;
      });
  }

  async signInWithGoogle() {
    try {
      const result = await signInWithPopup(this.auth, new GoogleAuthProvider());
      const user = result.user;

      const userData = await this.userService.getUserByUserId(user.uid);

      if (!userData) {
        const utilisateur: Utilisateur = {
          userId: user.uid,
          displayName: user.displayName || '',
          email: user.email || '',
          phoneNumber: user.phoneNumber || '',
        }
        await this.userService.createUser(utilisateur).then();
      }

      // Rafraîchir le token utilisateur si nécessaire
      await this.refreshUserToken();
      return result;
    } catch (error) {
      console.error('Erreur lors de la connexion avec Google :', error);
      throw error;
    }
  }

  signInWithFacebook() {
    return signInWithPopup(this.auth, new FacebookAuthProvider())
      .then((result) => {
        this.refreshUserToken();
        return result;
      });
  }

  logout(): Promise<void> {
    return signOut(this.auth).then(() => {
      this.userSubject.next(null);
    });
  }

  setUser(user: any){
    this.userSubject.next(user);
  }

  refreshUserToken(): Promise<void> {
    const user = this.auth.currentUser;
    if (user) {
      return user.getIdToken(true).then(() => {});
    }
    return Promise.resolve();
  }

  isAdmin(): Observable<boolean> {
    return this.user$.pipe(
      switchMap(user => {
        if (user) {
          return from(getIdTokenResult(user)).pipe(
            map(idTokenResult => idTokenResult.claims['admin'] === true)
          );
        } else {
          return of(false);
        }
      })
    );
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }
}
