import { Injectable } from '@angular/core';
import {
  collection,
  CollectionReference,
  doc,
  Firestore,
  getDocs,
  limit,
  query,
  QueryConstraint,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { ToastController } from '@ionic/angular/standalone';
import { FirestoreCrudService } from './firestore-service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends FirestoreCrudService<Utilisateur> {

  private readonly usersCollection: CollectionReference<any>;

  constructor(
    firestore: Firestore,
    private toastController: ToastController,
  ) {
    super(firestore, environment.databaseUsersName);
    this.usersCollection = collection(this.firestore, environment.databaseUsersName) as CollectionReference<any>;
  }

  // Create user
  async createUser(data: Utilisateur): Promise<string> {
    return await this.create(data);
  }

  // Get Event by ID
  async getUserById(id: string): Promise<Utilisateur & { id: string } | null> {
    return this.getById(id);
  }

  async getUserByUserId(
    userId: string ,
  ): Promise<Utilisateur[]> {
    let conditions: QueryConstraint[] = [];

    conditions.push(where('userId', '==', userId));
    conditions.push(limit(1)); // Limite la requête à un seul résultat

    const q = query( this.usersCollection, ...conditions);
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
  }

  async updatePhoneNumber(id: string, phoneNumber: string): Promise<boolean> {
    try {
      const userDoc = doc(this.usersCollection, id);
      await updateDoc(userDoc, { phoneNumber: phoneNumber });

      await this.presentToast("Numéro modifié", 'success');
      return true;
    } catch (error) {
      console.error(`Error modification numero: ${id}:`, error);
      return false;
    }
  }

  /**
   * Affiche un message toast.
   * @param message Le message à afficher.
   * @param color La couleur du toast ('success', 'danger', etc.).
   */
  private async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message,
      duration: 1500,
      color,
      position: 'middle',
      swipeGesture: 'vertical'
    });
    await toast.present();
  }
}

export interface Utilisateur {
  id?: string;
  userId?: string;
  displayName?: string;
  email?: string;
  phoneNumber?: string;
}

