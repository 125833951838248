import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IonBadge, IonButton, IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs } from '@ionic/angular/standalone';
import { AuthService } from '../service/auth.service';
import { NgIf } from '@angular/common';
import { combineLatest, Subscription } from 'rxjs';
import { BadgeService } from '../service/badge-service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
  standalone: true,
  imports: [RouterLink, IonTabButton, IonIcon, IonLabel, IonTabBar, IonTabs, NgIf, IonBadge, IonButton]
})
export class TabsPage implements OnInit, OnDestroy {
  isConnected: boolean = false;
  isAdmin: boolean = false;
  unreadCount: number = 0;

  private subscriptions: Subscription[] = [];

  constructor(private authService: AuthService, private badgeService: BadgeService) {
  }

  ngOnInit() {


    const isConnected$ = this.authService.user$;
    const isAdmin$ = this.authService.isAdmin();

    // Combine les deux observables
    const combinedSub = combineLatest([isConnected$, isAdmin$]).subscribe(
      ([user, isAdmin]) => {
        this.isConnected = !!user;
        this.isAdmin = isAdmin;

        // Une fois les deux valeurs disponibles, effectuez des actions
        if (this.isConnected && user) {
          // Arrêter le listener précédent s'il existe
          this.badgeService.stopListener();

          if (this.isAdmin) {
            // Démarrer le listener pour l'administrateur
            this.badgeService.startAdminBadgeListener();
          } else {
            // Démarrer le listener pour l'utilisateur standard
            const userId = user.uid; // Assurez-vous que l'utilisateur a un UID
            this.badgeService.startUserBadgeListener(userId);
          }

          // Souscrire au nombre d'événements non lus
          const countSub = this.badgeService.getUnreadCount().subscribe(
            (count) => {
              this.unreadCount = count;
            }
          );
          this.subscriptions.push(countSub);
        } else {
          // Si l'utilisateur n'est pas connecté, réinitialiser le badge et arrêter le listener
          this.unreadCount = 0;
          this.badgeService.stopListener();
        }
      }
    );

    this.subscriptions.push(combinedSub);
  }

  ngOnDestroy() {
    // Arrêter l'écouteur du service
    this.badgeService.stopListener();

    // Se désabonner de toutes les souscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
