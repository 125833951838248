import { bootstrapApplication } from '@angular/platform-browser';
import { PreloadAllModules, provideRouter, RouteReuseStrategy, withPreloading } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from './environments/environment.prod';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getFunctions, provideFunctions } from '@angular/fire/functions';


const prefersDark = localStorage.getItem('dark-mode') ;

if (prefersDark === null || prefersDark === 'true') {
  document.documentElement.classList.add('ion-palette-dark');
  if (prefersDark === null) {
    localStorage.setItem('dark-mode', 'true'); // Définit la préférence par défaut
  }
}

registerLocaleData(localeFr);

// register Swiper custom elements
register();

bootstrapApplication(AppComponent, {
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    provideAuth(() => getAuth()),
  ],

});

