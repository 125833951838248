import { Injectable } from '@angular/core';
import { collection, Firestore, onSnapshot, query, Query, where } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {
  private unreadCount$ = new BehaviorSubject<number>(0);
  private unsubscribe: (() => void) | null = null;

  constructor(private firestore: Firestore) { }

  // Méthode pour démarrer l'écoute des événements non lus pour un utilisateur standard
  startUserBadgeListener(userId: string) {
    const eventsRef = collection(this.firestore, environment.databaseEventsName);
    const unreadEventsQuery: Query = query(
      eventsRef,
      where('isReadByUser', '==', false),
      where('userId', '==', userId)
    );

    this.unsubscribe = onSnapshot(unreadEventsQuery, (snapshot) => {
      this.unreadCount$.next(snapshot.size);
    });
  }

  // Méthode pour démarrer l'écoute des événements non lus pour l'administrateur
  startAdminBadgeListener() {
    const eventsRef = collection(this.firestore, environment.databaseEventsName);
    const unreadAdminEventsQuery: Query = query(
      eventsRef,
      where('isReadByAdmin', '==', false)
    );

    this.unsubscribe = onSnapshot(unreadAdminEventsQuery, (snapshot) => {
      this.unreadCount$.next(snapshot.size);
    });
  }

  // Méthode pour obtenir l'observable du nombre d'événements non lus
  getUnreadCount() {
    return this.unreadCount$.asObservable();
  }

  // Méthode pour arrêter l'écouteur
  stopListener() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }

  // Méthodes pour marquer un événement comme lu
  async markEventAsReadByUser(eventId: string) {
    // Implémentation pour marquer un événement comme lu par l'utilisateur
  }

  async markEventAsReadByAdmin(eventId: string) {
    // Implémentation pour marquer un événement comme lu par l'administrateur
  }
}
