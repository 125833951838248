import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  AlertController,
  IonApp,
  IonCol,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterLink,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  NavController
} from '@ionic/angular/standalone';
import { AuthService } from './service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, CommonModule, IonApp, IonSplitPane, IonMenu, IonContent, IonList, IonListHeader, IonNote, IonMenuToggle, IonItem, IonIcon, IonLabel, IonRouterLink, IonRouterOutlet, IonImg, IonRow, IonCol, IonItemDivider],
})
export class AppComponent implements OnInit{

  isLoggedIn: boolean = false;
  private user: any;

  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  isAdmin: boolean = false;
  constructor(private authService: AuthService,
              private alertController: AlertController,
              private navCtrl: NavController) {
  }

  async ngOnInit(): Promise<void> {

    //Connexion utilisateur
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.isLoggedIn = true;
        this.user = user;
        console.log('Utilisateur connecté :', user);
      } else {
        this.isLoggedIn = false;
        this.user = null;
        console.log('Aucun utilisateur connecté');
      }
    });

    this.authService.isAdmin().subscribe((isAdmin) => (this.isAdmin = isAdmin));

  }

  async presentLogoutToast(event: Event) {
    const alert = await this.alertController.create({
      header: 'Déconnexion',
      message: 'Voulez-vous vraiment vous déconnecter ?',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel', // Permet de fermer l'alerte sans autre action
        },
        {
          text: 'Déconnecter',
          handler: () => {
            this.authService.logout(); // Appelle la méthode de déconnexion et redirection
          },
        },
      ],
    });

    await alert.present();
  }

}
