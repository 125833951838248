import { Routes } from '@angular/router';
import { TabsPage } from './tabs/tabs.page';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () => import('./sign/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'password',
    loadComponent: () => import('./sign/password/password.page').then(m => m.PasswordPage)
  },
  {
    path: 'signup',
    loadComponent: () => import('./sign/signup/signup.page').then(m => m.SignupPage)
  },
  {
    path: '',
    component: TabsPage,
    children: [
      {
        path: 'accueil',
        loadComponent: () => import('./tabs/accueil/accueil.page').then( m => m.AccueilPage)
      },
      {
        path: 'reservation',
        loadComponent: () => import('./tabs/reservation/reservation.page').then( m => m.ReservationPage)
      },
      {
        path: 'notification',
        loadComponent: () => import('./tabs/notification/notification.page').then( m => m.NotificationPage)
      },
      {
        path: 'agenda',
        loadComponent: () => import('./tabs/agenda/agenda.page').then( m => m.AgendaPage)
      },
      {
        path: '',
        redirectTo: '/accueil',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'parametre',
    loadComponent: () => import('./parametre/parametre.page').then( m => m.ParametrePage)
  },
  {
    path: 'profil',
    loadComponent: () => import('./profil/profil.page').then( m => m.ProfilPage)
  },
  {
    path: '**',
    redirectTo: 'accueil'
  },
];
