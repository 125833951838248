export const environment = {
  firebase: {
    apiKey: "AIzaSyBSd5aLlVTFq08sBETGtaLVmx-mWXz7dWw",
    authDomain: "studio-seven-fr.firebaseapp.com",
    projectId: "studio-seven-fr",
    storageBucket: "studio-seven-fr.appspot.com",
    messagingSenderId: "540882366681",
    appId: "1:540882366681:web:42dc409a2c165c89794ec8"
  },
  production: true,
  databaseEventsName: "events",
  databaseUsersName: "usersTest"
};
